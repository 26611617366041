import CookieConsentApi, { ICategory } from '@koaly/cookie-consent-api';
import { Button, ButtonGroup, Checkbox, CookieBar as KoalyCookieBar, Heading, Modal, Paragraph } from '@koaly/koaly-ui';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Link } from '../Link/Link';

export const CookieBar: React.FC = () => {
  const [isOpen, setOpen] = useState(false);
  const [isModelOpen, setModelOpen] = useState(false);
  const { register, handleSubmit } = useForm();
  const categories = useRef<ICategory[]>([]);
  let concentApi: CookieConsentApi | undefined = undefined;

  let acceptButtonOnClick: () => void | null = () => null;
  if (typeof (window) !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    concentApi = window ? (window.CookieConsentApi) as CookieConsentApi : undefined;
    if (concentApi) {
      categories.current = concentApi.getCategories();
      acceptButtonOnClick = () => {
        if (concentApi) {
          concentApi.acceptAll();
        }
        setOpen(false);
        setModelOpen(false);
      };

      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (concentApi.getConsentCookie().length === 0) {
          setOpen(true);
        }
      }, [concentApi]);
    }
  }

  const onSubmit = (data: { [key: string]: boolean }) => {
    for (const category in data) {
      if (concentApi) {
        if (data[category]) {
          concentApi.acceptByCategory(category);
        } else {
          concentApi.disableByCategory(category);
        }
      }
    }

    setModelOpen(false);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <KoalyCookieBar
        floating={true}
        open={isOpen}
        manageButtonOnClick={() => setModelOpen(true)}
        acceptButtonOnClick={acceptButtonOnClick}
      >
        <p style={{ margin: 0 }}>
          Wij maken op deze website gebruik van cookies om je de beste ervaring te bieden.
          {' '}<Link variant="secondary" to="/privacy#cookies">Meer info.</Link>
        </p>
      </KoalyCookieBar>
      <Modal
        open={isModelOpen}
        title="Cookie instellingen"
        onClose={() => setModelOpen(false)}
      >
        <Paragraph spaceAfter={12}>
          Cookies zijn kleine tekstbestanden die door websites kunnen worden gebruikt om de gebruikerservaring
          efficiënter te maken. De wet stelt dat we cookies op uw apparaat kunnen opslaan als ze strikt noodzakelijk
          zijn voor de werking van deze site. Voor alle andere soorten cookies hebben we uw toestemming nodig. Deze site
          gebruikt verschillende soorten cookies. Sommige cookies worden geplaatst door diensten van derden
          die op onze pagina's verschijnen. U kunt uw toestemming te allen tijde wijzigen of intrekken in de
          cookieverklaring op onze website. Lees meer over wie we zijn, hoe u contact met ons kunt opnemen en hoe we
          persoonsgegevens verwerken in ons privacybeleid.
        </Paragraph>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Heading size="h3" spaceAfter={8}>Selecteer welke soort cookies u wilt accepteren:</Heading>
          {categories.current.map((category) =>
            (
              <React.Fragment>
                <Checkbox
                  key={category.id}
                  size="large"
                  id={category.id}
                  name={category.id}
                  defaultChecked={concentApi ? concentApi.hasConsent('category', category.id) : false}
                  checked={category.force}
                  disabled={category.force}
                  label={category.name}
                  ref={register}
                  spaceAfter={category.description ? 4 : 8}
                />
                {category.description && (
                  <Paragraph size="small" spaceAfter={8}>
                    {category.description}
                  </Paragraph>
                )}
              </React.Fragment>
            ),
          )}
          <div style={{ marginBottom: '50px' }} />
          <ButtonGroup spacing={4}>
            <Button variant="secondary" type="submit" elevation={2}>Opslaan</Button>
            <Button variant="primary" type="button" onClick={acceptButtonOnClick} elevation={2}>Accepteer alles</Button>
          </ButtonGroup>
        </form>
      </Modal>
    </React.Fragment>
  );
};
