import { Link as KoalyLink } from '@koaly/koaly-ui';
import NextLink from 'next/link';
import React from 'react';

interface ILink {
  to: string;
  variant?: 'primary' | 'secondary'
}


export const Link: React.FC<ILink> = ({ children, to, variant }) => {
  return (
    <NextLink href={to}>
      <KoalyLink variant={variant} href={to}>
        {children}
      </KoalyLink>
    </NextLink>
  );
};
