import { createGlobalStyle } from 'styled-components';

export const GlobalStyling = createGlobalStyle`
  body {
    margin:0;
  }

  #__next {
    overflow: hidden;
  }
`;
