import { koalyTheme, ThemeProvider } from '@koaly/koaly-ui';
import { nextLinkWrapper } from '@koaly/koaly-ui/integrations/router/next';
import { AppType } from 'next/dist/next-server/lib/utils';
import { useRouter } from 'next/router';
import React from 'react';

import { CookieBar } from '../components/CookieBar/CookieBar';
import { GlobalStyling } from '../components/GlobalStyling/GlobalStyling';

const App: AppType = ({ Component, pageProps }) => {
  const router = useRouter();
  const linkWrapper = nextLinkWrapper(router);
  return (
    <ThemeProvider theme={koalyTheme} linkWrap={linkWrapper}>
      <GlobalStyling />
      <CookieBar />
      <Component {...pageProps} />
    </ThemeProvider>
  );
};

export default App;
