import Link from 'next/link';
import { NextRouter } from 'next/router';
import React, { ReactElement } from 'react';

const nextLinkWrapper = (router: NextRouter) => (link: ReactElement): React.ReactElement => {
  // Disable because useRouter works inside this function so not needed in render method.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { props: { className: classes, href }, key } = link;
  const className = router.pathname === href || router.asPath === href ? `${classes || ''} active` : classes;

  if (!href) {
    return link;
  }

  // Checking if the link external that way we want the default behavior so returning link will do the job.
  if (/^(?:[a-z]+:)?\/\//.exec(href)) {
    return link;
  }

  if (/^\#/.exec(href)) {
    return link;
  }

  return <Link href={href} key={key ?? undefined}>{React.cloneElement(link, { className })}</Link>;
};

export { nextLinkWrapper };
